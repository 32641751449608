"use strict";
exports.__esModule = true;
var vue_1 = require("vue");
var vue_2 = require("vue");
exports["default"] = (0, vue_1.defineComponent)({
    __name: 'Navigation',
    props: {
        isAdmin: null,
        user: null,
        company: null
    },
    setup: function (__props, _a) {
        var expose = _a.expose;
        expose();
        var props = __props;
        var actualPage = window.location.pathname;
        var isShow = (0, vue_2.ref)(false);
        var isSmallScreen = (0, vue_2.ref)(false);
        var xPosition = (0, vue_2.ref)();
        var yPosition = (0, vue_2.ref)();
        function showHideSubMenu(event) {
            isShow.value = !isShow.value;
            var subMenuParent = event.target;
            isSmallScreen.value = window.innerWidth < 767;
            xPosition.value =
                window.scrollX + subMenuParent.getBoundingClientRect().left;
            yPosition.value =
                window.innerHeight - subMenuParent.getBoundingClientRect().top;
        }
        var __returned__ = { props: props, actualPage: actualPage, isShow: isShow, isSmallScreen: isSmallScreen, xPosition: xPosition, yPosition: yPosition, showHideSubMenu: showHideSubMenu, ref: vue_2.ref };
        Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true });
        return __returned__;
    }
});
