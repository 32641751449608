"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
var vue_1 = require("vue");
var runtime_core_1 = require("@vue/runtime-core");
var vue_2 = require("vue");
var Utilities_1 = require("./Utilities");
var luxon_1 = require("luxon");
exports["default"] = (0, vue_1.defineComponent)({
    __name: 'ReportsDashboard',
    props: {
        reportsTypes: {
            type: Object,
            required: true
        }
    },
    setup: function (__props, _a) {
        var expose = _a.expose;
        expose();
        var props = __props;
        var REFERRALS_REPORT = "Referrals";
        var ONE_ON_ONES_REPOTS = "One On Ones";
        var ATTENDANCE_REPOTS = "Attendance";
        var INTRODUCTIONS_REPOTS = "Introductions";
        var reportTypes = [REFERRALS_REPORT, ATTENDANCE_REPOTS, INTRODUCTIONS_REPOTS, ONE_ON_ONES_REPOTS];
        var reportActionsData = {
            Referrals: {
                api: "/api/get-referral-report-data",
                handler: handleReferralReport
            },
            "One On Ones": {
                api: "/api/get-one-on-one-report-data",
                handler: handleOneOnOneReport
            },
            Attendance: {
                api: "/api/get-attendance-report-data",
                handler: handleAttendanceReport
            },
            Introductions: {
                api: "/api/get-introductions-report-data",
                handler: handleIntroductionsReport
            }
        };
        var today = luxon_1.DateTime.now().toFormat('MM-dd-yyyy');
        var selectedReport = REFERRALS_REPORT;
        var reportDateRage = (0, vue_2.ref)();
        var reportStartDateRage = (0, vue_2.ref)();
        var reportEndDateRage = (0, vue_2.ref)();
        var reportStartDate = (0, vue_2.ref)();
        var reportEndDate = (0, vue_2.ref)();
        var csrfToken = (0, vue_2.ref)();
        var modelConfig = (0, vue_2.ref)({
            type: "string",
            mask: "YYYY-MM-DD"
        });
        var reportData = (0, vue_2.ref)();
        var successMessage = (0, vue_2.ref)();
        var errorMessage = (0, vue_2.ref)();
        var data = (0, vue_2.ref)();
        function clearData(event) {
            reportData.value = [];
            errorMessage.value = null;
            successMessage.value = null;
            data.value = false;
        }
        function generateReport(event) {
            return __awaiter(this, void 0, void 0, function () {
                var form, reportType, formData, request, response, report, handler;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            event.preventDefault();
                            form = event.target;
                            if (!(reportDateRage.value && form instanceof HTMLFormElement)) return [3 /*break*/, 3];
                            successMessage.value = null;
                            errorMessage.value = null;
                            data.value = false;
                            reportType = form.reportType.value;
                            formData = new FormData(form);
                            reportStartDateRage.value = reportDateRage.value.start + ' 00:00:00';
                            reportEndDateRage.value = reportDateRage.value.end + ' 23:59:59';
                            reportStartDate.value = reportDateRage.value.start;
                            reportEndDate.value = reportDateRage.value.end;
                            reportDateRage.value = null;
                            formData.append("reportStartDate", reportStartDateRage.value);
                            formData.append("reportEndDate", reportEndDateRage.value);
                            request = buildRequestReport(reportType, formData);
                            return [4 /*yield*/, fetch(request)];
                        case 1:
                            response = _a.sent();
                            return [4 /*yield*/, response.json()];
                        case 2:
                            report = _a.sent();
                            handler = getReportHandler(reportType);
                            handler(report.data);
                            return [3 /*break*/, 4];
                        case 3:
                            errorMessage.value = "Please select a date range.";
                            console.log("No data range selected");
                            _a.label = 4;
                        case 4: return [2 /*return*/];
                    }
                });
            });
        }
        function handleReferralReport(referralReportData) {
            console.log("handleReferralReport", referralReportData);
            reportData.value = referralReportData;
            console.log(reportData.value.referrals);
            if (reportData.value.referrals.length == 0) {
                errorMessage.value = "No records found.";
                data.value = false;
            }
            else {
                data.value = true;
                errorMessage.value = null;
            }
        }
        function handleOneOnOneReport(oneOnOneReportData) {
            console.log("handleOneOnOneReport", oneOnOneReportData);
            if (!oneOnOneReportData) {
                oneOnOneReportData = { oneonone: [] };
            }
            reportData.value = oneOnOneReportData;
            if (reportData.value.oneonone.length == 0) {
                errorMessage.value = "No records found.";
                data.value = false;
            }
            else {
                data.value = true;
                errorMessage.value = null;
            }
        }
        function handleAttendanceReport(AttendanceReportData) {
            console.log("handleAttendanceReport", AttendanceReportData);
            reportData.value = AttendanceReportData;
            if (reportData.value.sessions.length == 0) {
                errorMessage.value = "No records found.";
                data.value = false;
            }
            else {
                data.value = true;
                errorMessage.value = null;
            }
        }
        function handleIntroductionsReport(IntroductionsReportData) {
            console.log("handleIntroductionsReport", IntroductionsReportData);
            if (!IntroductionsReportData) {
                IntroductionsReportData = { introductions: [] };
            }
            reportData.value = IntroductionsReportData;
            if (reportData.value.introductions.length == 0) {
                errorMessage.value = "No records found.";
                data.value = false;
            }
            else {
                data.value = true;
                errorMessage.value = null;
            }
        }
        function buildRequestReport(reportType, requestData) {
            requestData.append("_token", csrfToken.value);
            var request = new Request(reportActionsData[reportType].api, {
                method: "Post",
                body: requestData
            });
            return request;
        }
        function getReportHandler(reportType) {
            return reportActionsData[reportType].handler;
        }
        (0, runtime_core_1.onBeforeMount)(function () {
            var tokenMetaTag = document.querySelector('meta[name="csrf-token"]');
            var csrfTokenValue = (0, Utilities_1.getCsrfToken)(tokenMetaTag);
            if (!csrfTokenValue) {
                throw new Error("Unable to find CSRF token in the document.");
            }
            csrfToken.value = csrfTokenValue;
            console.log(props.reportsTypes);
        });
        var __returned__ = { REFERRALS_REPORT: REFERRALS_REPORT, ONE_ON_ONES_REPOTS: ONE_ON_ONES_REPOTS, ATTENDANCE_REPOTS: ATTENDANCE_REPOTS, INTRODUCTIONS_REPOTS: INTRODUCTIONS_REPOTS, reportTypes: reportTypes, reportActionsData: reportActionsData, props: props, today: today, selectedReport: selectedReport, reportDateRage: reportDateRage, reportStartDateRage: reportStartDateRage, reportEndDateRage: reportEndDateRage, reportStartDate: reportStartDate, reportEndDate: reportEndDate, csrfToken: csrfToken, modelConfig: modelConfig, reportData: reportData, successMessage: successMessage, errorMessage: errorMessage, data: data, clearData: clearData, generateReport: generateReport, handleReferralReport: handleReferralReport, handleOneOnOneReport: handleOneOnOneReport, handleAttendanceReport: handleAttendanceReport, handleIntroductionsReport: handleIntroductionsReport, buildRequestReport: buildRequestReport, getReportHandler: getReportHandler, onBeforeMount: runtime_core_1.onBeforeMount, ref: vue_2.ref, getCsrfToken: Utilities_1.getCsrfToken, getDateFormatedFromISO: Utilities_1.getDateFormatedFromISO, DateTime: luxon_1.DateTime };
        Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true });
        return __returned__;
    }
});
