"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
var vue_1 = require("vue");
var vue_2 = require("vue");
var Utilities_1 = require("./Utilities");
exports["default"] = (0, vue_1.defineComponent)({
    __name: 'AttendancePage',
    props: {
        session: {
            type: Object,
            required: true
        },
        sessionAttendance: {
            type: Object,
            required: true
        },
        users: {
            type: Object,
            required: true
        }
    },
    setup: function (__props, _a) {
        var expose = _a.expose;
        expose();
        var props = __props;
        var csrfToken = (0, vue_2.ref)("");
        var sessionTime = (0, vue_2.ref)((0, Utilities_1.getTimeFromTimestamp)(props.session.sessionTimestamp, Intl.DateTimeFormat().resolvedOptions().timeZone));
        var PRESENT = "present";
        var ABSENT = "absent";
        var PROXY = "proxy";
        var SUBSTITUTE = "substitue";
        var sessionAttendanceStates = [
            { value: ABSENT, label: "Absent" },
            { value: PRESENT, label: "Present" },
            { value: PROXY, label: "Proxy" },
            { value: SUBSTITUTE, label: "Substitute" },
        ];
        var attendanceList = (0, vue_2.ref)(createAttendanceList());
        var successMessage = (0, vue_2.ref)("");
        var errorMessage = (0, vue_2.ref)("");
        var errorDetail = (0, vue_2.ref)("");
        function attendanceStyleClass(status) {
            if (status === void 0) { status = ABSENT; }
            var attendanceStyleClasses = {
                absent: "input-absent bg-secondary text-white",
                present: "input-present bg-success text-white",
                proxy: "input-proxy bg-warning text-dark",
                substitue: "input-substitute bg-info text-white"
            };
            return attendanceStyleClasses[status];
        }
        function createAttendanceList() {
            return props.users.map(function (user) {
                var _a, _b;
                return ({
                    userFullName: user.firstName + " " + user.lastName,
                    userId: user.id,
                    attendance: ((_a = getAttendaceValue(user.id)) === null || _a === void 0 ? void 0 : _a.attendance) || ABSENT,
                    notes: ((_b = getAttendaceValue(user.id)) === null || _b === void 0 ? void 0 : _b.notes) || null,
                    sessionId: props.session.id
                });
            });
        }
        function getAttendaceValue(userId) {
            return props.sessionAttendance.find(function (element) {
                return element.userId == userId;
            });
        }
        function getProxyUsers(userId) {
            return props.users.filter(function (user) { return user.id != userId; });
        }
        function saveAttendance(e) {
            return __awaiter(this, void 0, void 0, function () {
                var request, response, attendanceResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            e.preventDefault();
                            request = new Request("/api/update-user-session-attendance", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "text/json",
                                    "X-CSRF-TOKEN": csrfToken.value
                                },
                                body: JSON.stringify(attendanceList.value)
                            });
                            return [4 /*yield*/, fetch(request)];
                        case 1:
                            response = _a.sent();
                            return [4 /*yield*/, response.json()];
                        case 2:
                            attendanceResponse = _a.sent();
                            if (attendanceResponse.success) {
                                successMessage.value = "Attendance saved";
                            }
                            else if (attendanceResponse.error) {
                                errorMessage.value = "Unexpected error";
                                console.error(attendanceResponse.error);
                            }
                            else {
                                errorMessage.value = attendanceResponse.message;
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
        (0, vue_2.onBeforeMount)(function () {
            return __awaiter(this, void 0, void 0, function () {
                var tokenMetaTag, csrfTokenValue;
                return __generator(this, function (_a) {
                    tokenMetaTag = document.querySelector('meta[name="csrf-token"]');
                    csrfTokenValue = (0, Utilities_1.getCsrfToken)(tokenMetaTag);
                    if (!csrfTokenValue) {
                        throw new Error("Unable to find CSRF token in the document.");
                    }
                    csrfToken.value = csrfTokenValue;
                    return [2 /*return*/];
                });
            });
        });
        var __returned__ = { props: props, csrfToken: csrfToken, sessionTime: sessionTime, PRESENT: PRESENT, ABSENT: ABSENT, PROXY: PROXY, SUBSTITUTE: SUBSTITUTE, sessionAttendanceStates: sessionAttendanceStates, attendanceList: attendanceList, successMessage: successMessage, errorMessage: errorMessage, errorDetail: errorDetail, attendanceStyleClass: attendanceStyleClass, createAttendanceList: createAttendanceList, getAttendaceValue: getAttendaceValue, getProxyUsers: getProxyUsers, saveAttendance: saveAttendance, ref: vue_2.ref, onBeforeMount: vue_2.onBeforeMount, getCsrfToken: Utilities_1.getCsrfToken, getTimeFromTimestamp: Utilities_1.getTimeFromTimestamp };
        Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true });
        return __returned__;
    }
});
