"use strict";
exports.__esModule = true;
var vue_1 = require("vue");
var vue_2 = require("vue");
var luxon_1 = require("luxon");
exports["default"] = (0, vue_1.defineComponent)({
    __name: 'PresentationDaysSelect',
    props: {
        group: null,
        presentations: null,
        selectName: null
    },
    setup: function (__props, _a) {
        var expose = _a.expose;
        expose();
        var props = __props;
        var WEEK_DAYS = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
        ];
        var groupMeetingDays = (0, vue_2.ref)(getMeetingDaysInNextQuarter());
        function getPresenterName(day) {
            if (!props.presentations)
                return "";
            var presenter = props.presentations.find(function (presentation) { return presentation.presentationDate == day; });
            if (!presenter)
                return "";
            return presenter.presenterName + " " + presenter.presenterLastName;
        }
        function getMeetingDaysInNextQuarter() {
            var start = luxon_1.DateTime.now();
            var end = luxon_1.DateTime.now().plus({ quarter: 1 });
            var weekDay = WEEK_DAYS.findIndex(function (day) {
                return day.toLowerCase() == props.group.meetingWeekDay.toLowerCase();
            }) + 1;
            var dates = [];
            var date = start;
            var weekNumber = start.weekNumber;
            do {
                if (weekNumber > date.weeksInWeekYear) {
                    weekNumber = 1;
                    date = date.set({ year: date.year + 1 });
                }
                date = date.set({
                    weekday: weekDay,
                    weekNumber: weekNumber++
                });
                dates.push(date);
            } while (date < end);
            return dates.map(function (date) {
                return {
                    month: date.monthLong,
                    year: date.year,
                    sqlDate: date.toSQLDate(),
                    localDate: date.toLocaleString(luxon_1.DateTime.DATE_FULL),
                    presenterFullName: getPresenterName(date.toSQLDate())
                };
            });
        }
        var __returned__ = { props: props, WEEK_DAYS: WEEK_DAYS, groupMeetingDays: groupMeetingDays, getPresenterName: getPresenterName, getMeetingDaysInNextQuarter: getMeetingDaysInNextQuarter, ref: vue_2.ref, DateTime: luxon_1.DateTime };
        Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true });
        return __returned__;
    }
});
