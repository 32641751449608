"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
var vue_1 = require("vue");
var Utilities_1 = require("./Utilities");
var vue_2 = require("vue");
var luxon_1 = require("luxon");
exports["default"] = (0, vue_1.defineComponent)({
    __name: 'OneOnOneModal',
    props: {
        meeting: {
            type: Object,
            required: true
        },
        dismissModal: {
            type: Function,
            required: true
        },
        creator: Boolean,
        errorMessage: String,
        successMessage: String,
        submitChange: { type: Function, required: true },
        rescheduleAction: { type: Function, required: true }
    },
    setup: function (__props, _a) {
        var expose = _a.expose;
        expose();
        var props = __props;
        var WAITING = "waiting";
        var ACCEPTED = "accepted";
        var REJECTED = "rejected";
        var CANCELLED = "cancelled";
        var DONE = "done";
        var SIGNED_BY_CREATOR = "signed by creator";
        var SIGNED_BY_INVITED = "signed by invited";
        var CONCLUSIVE_STATUSES = [REJECTED, CANCELLED, DONE];
        var dateModel = (0, vue_2.ref)(null);
        var localTimezone = (0, vue_2.ref)(Intl.DateTimeFormat().resolvedOptions().timeZone);
        var csrfToken = (0, vue_2.ref)("");
        var minDateToChoose = (0, vue_2.ref)("");
        (0, vue_2.onBeforeMount)(function () {
            return __awaiter(this, void 0, void 0, function () {
                var tokenMetaTag, csrfTokenValue;
                return __generator(this, function (_a) {
                    getDateLimit();
                    tokenMetaTag = document.querySelector('meta[name="csrf-token"]');
                    csrfTokenValue = (0, Utilities_1.getCsrfToken)(tokenMetaTag);
                    if (!csrfTokenValue) {
                        throw new Error("Unable to find CSRF token in the document.");
                    }
                    csrfToken.value = csrfTokenValue;
                    return [2 /*return*/];
                });
            });
        });
        function getDateLimit() {
            var dateTimeFormat = "yyyy-MM-dd'T'HH:mm";
            minDateToChoose.value = luxon_1.DateTime.now().toFormat(dateTimeFormat);
        }
        function meetingDateReached(timestamp) {
            var now = Date.now();
            return now > Number(timestamp);
        }
        function rescheduleFormSubmit(event) {
            event.preventDefault();
            console.log("rescheduleFormSubmit");
            var target = event.target;
            if (target instanceof HTMLFormElement && target.dataset.meeting) {
                console.log("HTMLFormElement");
                var formData = new FormData(target);
                formData.append("invitation_id", target.dataset.meeting);
                var request = buildRequestChangeInvitationStatus("reschedule", formData);
                props.rescheduleAction();
                props.submitChange(request);
            }
        }
        function cancelInvitationFormSumbit(event) {
            event.preventDefault();
            var target = event.target;
            if (target instanceof HTMLElement && target.dataset.meeting) {
                var formData = new FormData();
                formData.append("invitation_id", target.dataset.meeting);
                var request = buildRequestChangeInvitationStatus("cancel", formData);
                props.submitChange(request);
            }
        }
        function acceptInvitationFormSubmit(event) {
            event.preventDefault();
            var target = event.target;
            if (target instanceof HTMLElement && target.dataset.meeting) {
                var formData = new FormData();
                formData.append("invitation_id", target.dataset.meeting);
                var request = buildRequestChangeInvitationStatus("accept", formData);
                props.submitChange(request);
            }
        }
        function rejectInvitationFormSubmit(event) {
            event.preventDefault();
            var target = event.target;
            if (target instanceof HTMLElement && target.dataset.meeting) {
                var formData = new FormData();
                formData.append("invitation_id", target.dataset.meeting);
                var request = buildRequestChangeInvitationStatus("decline", formData);
                props.submitChange(request);
            }
        }
        function concludeMeeting(event) {
            return __awaiter(this, void 0, void 0, function () {
                var formData, request;
                return __generator(this, function (_a) {
                    event.preventDefault();
                    if (event.target instanceof HTMLFormElement) {
                        formData = new FormData(event.target);
                        request = buildRequestChangeInvitationStatus("conclude", formData);
                        props.submitChange(request);
                    }
                    return [2 /*return*/];
                });
            });
        }
        function buildRequestChangeInvitationStatus(action, requestData) {
            requestData.append("_token", csrfToken.value);
            var apis = {
                accept: "/api/accept-one-on-one",
                decline: "/api/reject-one-on-one",
                cancel: "/api/cancel-one-on-one",
                conclude: "/api/conclude-one-on-one",
                reschedule: "/api/reschedule-one-on-one"
            };
            var request = new Request(apis[action], {
                method: "Post",
                body: requestData
            });
            return request;
        }
        var __returned__ = { props: props, WAITING: WAITING, ACCEPTED: ACCEPTED, REJECTED: REJECTED, CANCELLED: CANCELLED, DONE: DONE, SIGNED_BY_CREATOR: SIGNED_BY_CREATOR, SIGNED_BY_INVITED: SIGNED_BY_INVITED, CONCLUSIVE_STATUSES: CONCLUSIVE_STATUSES, dateModel: dateModel, localTimezone: localTimezone, csrfToken: csrfToken, minDateToChoose: minDateToChoose, getDateLimit: getDateLimit, meetingDateReached: meetingDateReached, rescheduleFormSubmit: rescheduleFormSubmit, cancelInvitationFormSumbit: cancelInvitationFormSumbit, acceptInvitationFormSubmit: acceptInvitationFormSubmit, rejectInvitationFormSubmit: rejectInvitationFormSubmit, concludeMeeting: concludeMeeting, buildRequestChangeInvitationStatus: buildRequestChangeInvitationStatus, getCsrfToken: Utilities_1.getCsrfToken, getTimeFromTimestamp: Utilities_1.getTimeFromTimestamp, ref: vue_2.ref, onBeforeMount: vue_2.onBeforeMount, DateTime: luxon_1.DateTime };
        Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true });
        return __returned__;
    }
});
