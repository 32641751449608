"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
var vue_1 = require("vue");
var vue_2 = require("vue");
var Utilities_1 = require("./Utilities");
exports["default"] = (0, vue_1.defineComponent)({
    __name: 'Referrals',
    props: {
        created: null,
        received: null
    },
    setup: function (__props, _a) {
        var expose = _a.expose;
        expose();
        var props = __props;
        var RECEIVED = "received";
        var CREATED = "created";
        var selectedTab = (0, vue_2.ref)(RECEIVED);
        var created = (0, vue_2.ref)(props.created);
        var received = (0, vue_2.ref)(props.received);
        var WAITING = "waiting";
        var BEING_QUALIFIED = "being qualified";
        var IN_DISCUSSION = "in discussion";
        var CONVERTED = "converted";
        var COMPLETED = "done";
        var NOT_CONVERTED = "not converted";
        var UNQUALIFIED = "unqualified";
        var selectedFilter = (0, vue_2.ref)(WAITING);
        var referralSelected = (0, vue_2.ref)(null);
        var isReferralSelectedCreatedByUser = (0, vue_2.ref)(null);
        var successMessage = (0, vue_2.ref)(null);
        var errorMessage = (0, vue_2.ref)(null);
        var csrfToken = (0, vue_2.ref)();
        function dismissReferralModal() {
            referralSelected.value = null;
            isReferralSelectedCreatedByUser.value = null;
            successMessage.value = null;
            errorMessage.value = null;
        }
        function showReferralModal(referral, isByUser) {
            referralSelected.value = referral;
            isReferralSelectedCreatedByUser.value = isByUser;
        }
        function getReferralsByStatus(filter) {
            if (selectedTab.value == RECEIVED) {
                return received.value.filter(function (referral) { return referral.status == filter; });
            }
            if (selectedTab.value == CREATED) {
                return created.value.filter(function (referral) { return referral.status == filter; });
            }
        }
        function changeReferralStatus(referral, notes, value, newStatus) {
            return __awaiter(this, void 0, void 0, function () {
                var referralData, request, response, jsonResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!referral) {
                                return [2 /*return*/];
                            }
                            if (referral.status != CONVERTED && !notes) {
                                errorMessage.value = "Notes are mandatory";
                                return [2 /*return*/];
                            }
                            if (referral.status == CONVERTED && !value) {
                                errorMessage.value = "Value is mandatory";
                                return [2 /*return*/];
                            }
                            if (!newStatus) {
                                errorMessage.value = "New status is undefined";
                                return [2 /*return*/];
                            }
                            referralData = new FormData();
                            referralData.append("referralId", referral.id.toString());
                            referralData.append("new_status", newStatus);
                            referralData.append("notes", notes);
                            referralData.append("value", value);
                            referralData.append("_token", csrfToken.value);
                            request = new Request("/api/update-referral-status", {
                                method: "POST",
                                body: referralData
                            });
                            successMessage.value = null;
                            errorMessage.value = null;
                            return [4 /*yield*/, fetch(request)];
                        case 1:
                            response = _a.sent();
                            return [4 /*yield*/, response.json()];
                        case 2:
                            jsonResponse = _a.sent();
                            if (jsonResponse.success) {
                                referralSelected.value = jsonResponse.data;
                                successMessage.value = "Referral updated successfully";
                                reloadReferrals();
                            }
                            else {
                                errorMessage.value = jsonResponse.message
                                    ? jsonResponse.message
                                    : "Unexpected response from the server.";
                            }
                            if (jsonResponse.error) {
                                errorMessage.value = jsonResponse.error;
                            }
                            else {
                                errorMessage.value = "";
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
        function reloadReferrals() {
            return __awaiter(this, void 0, void 0, function () {
                var response, referralsReceived;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, fetch("/api/list-received-referrals")];
                        case 1:
                            response = _a.sent();
                            return [4 /*yield*/, response.json()];
                        case 2:
                            referralsReceived = _a.sent();
                            received.value = referralsReceived.data;
                            return [2 /*return*/];
                    }
                });
            });
        }
        (0, vue_2.onBeforeMount)(function () {
            var tokenMetaTag = document.querySelector('meta[name="csrf-token"]');
            csrfToken.value = (0, Utilities_1.getCsrfToken)(tokenMetaTag);
            if (!csrfToken.value) {
                throw new Error("Unable to find CSRF token in the document.");
            }
        });
        var __returned__ = { props: props, RECEIVED: RECEIVED, CREATED: CREATED, selectedTab: selectedTab, created: created, received: received, WAITING: WAITING, BEING_QUALIFIED: BEING_QUALIFIED, IN_DISCUSSION: IN_DISCUSSION, CONVERTED: CONVERTED, COMPLETED: COMPLETED, NOT_CONVERTED: NOT_CONVERTED, UNQUALIFIED: UNQUALIFIED, selectedFilter: selectedFilter, referralSelected: referralSelected, isReferralSelectedCreatedByUser: isReferralSelectedCreatedByUser, successMessage: successMessage, errorMessage: errorMessage, csrfToken: csrfToken, dismissReferralModal: dismissReferralModal, showReferralModal: showReferralModal, getReferralsByStatus: getReferralsByStatus, changeReferralStatus: changeReferralStatus, reloadReferrals: reloadReferrals, ref: vue_2.ref, onBeforeMount: vue_2.onBeforeMount, reactive: vue_2.reactive, getCsrfToken: Utilities_1.getCsrfToken };
        Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true });
        return __returned__;
    }
});
