"use strict";
exports.__esModule = true;
exports.isBoolean = exports.isNumber = exports.isString = exports.isObject = exports.isArrayOf = exports.isArray = exports.hasOwnProperty = void 0;
function hasOwnProperty(object, propertyKey) {
    return object instanceof Object && object.hasOwnProperty(propertyKey);
}
exports.hasOwnProperty = hasOwnProperty;
function isArray(value) {
    return Array.isArray(value);
}
exports.isArray = isArray;
function isArrayOf(value, itemTypeMatcher) {
    return (isArray(value) && value.filter(itemTypeMatcher).length === value.length);
}
exports.isArrayOf = isArrayOf;
function isObject(object) {
    return typeof object === "object" || object instanceof Object;
}
exports.isObject = isObject;
function isString(value) {
    return typeof value === "string";
}
exports.isString = isString;
function isNumber(value) {
    return typeof value === "number";
}
exports.isNumber = isNumber;
function isBoolean(value) {
    return value === true || value === false;
}
exports.isBoolean = isBoolean;
