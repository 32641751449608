"use strict";
exports.__esModule = true;
var createdStatus = "waiting";
var rejectedStatus = "rejected";
var beingQualifiedStatus = "being qualified";
var unqualifiedStatus = "unqualified";
var inDiscussionStatus = "in discussion";
var convertedStatus = "converted";
var notConvertedStatus = "not converted";
var doneStatus = "done";
exports["default"] = [
    {
        status: createdStatus,
        statusUpdateOptions: [
            {
                label: "Reject",
                newStatus: rejectedStatus
            },
            {
                label: "Accept",
                newStatus: beingQualifiedStatus
            },
        ]
    },
    {
        status: beingQualifiedStatus,
        statusUpdateOptions: [
            {
                label: "Unqualify",
                newStatus: unqualifiedStatus
            },
            {
                label: "Qualify",
                newStatus: inDiscussionStatus
            },
        ]
    },
    {
        status: inDiscussionStatus,
        statusUpdateOptions: [
            {
                label: "Converted",
                newStatus: convertedStatus
            },
            {
                label: "Not Converted",
                newStatus: notConvertedStatus
            },
        ]
    },
    {
        status: convertedStatus,
        statusUpdateOptions: [
            {
                label: "Done",
                newStatus: doneStatus
            },
        ]
    },
];
