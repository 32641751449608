"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
var vue_1 = require("vue");
var vue_2 = require("vue");
exports["default"] = (0, vue_1.defineComponent)({
    __name: 'ResetPasswordForm',
    props: {
        token: {
            type: String,
            required: true
        }
    },
    setup: function (__props, _a) {
        var expose = _a.expose;
        expose();
        var props = __props;
        var isFormVisible = (0, vue_2.ref)(true);
        var emailInput = (0, vue_2.ref)("");
        var passwordInput = (0, vue_2.ref)("");
        var confirmPasswordInput = (0, vue_2.ref)("");
        var successMessage = (0, vue_2.ref)("");
        var errorMessage = (0, vue_2.ref)("");
        var errorDetail = (0, vue_2.ref)("");
        function resetPasswordClick(e) {
            return __awaiter(this, void 0, void 0, function () {
                var csrfTokenElement, csrfToken, response, responseBodyText, responseBody;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            e.preventDefault();
                            console.log("resetPasswordClick");
                            csrfTokenElement = document.querySelector('meta[name="csrf-token"]');
                            csrfToken = csrfTokenElement
                                ? csrfTokenElement.getAttribute("content")
                                : null;
                            if (!csrfToken) {
                                throw new Error("Unable to find CSRF token in the document.");
                            }
                            return [4 /*yield*/, fetch("/api/reset-password", {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "text/json",
                                        "X-CSRF-TOKEN": csrfToken
                                    },
                                    body: JSON.stringify({
                                        email: emailInput.value,
                                        password: passwordInput.value,
                                        password_confirmation: confirmPasswordInput.value,
                                        token: props.token,
                                        _token: csrfToken
                                    })
                                })];
                        case 1:
                            response = _a.sent();
                            return [4 /*yield*/, response.text()];
                        case 2:
                            responseBodyText = _a.sent();
                            responseBody = JSON.parse(responseBodyText);
                            if (responseBody.success) {
                                errorMessage.value = "";
                                successMessage.value = responseBody.data;
                                isFormVisible.value = false;
                            }
                            else {
                                errorMessage.value = responseBody.message
                                    ? responseBody.message
                                    : "Unexpected response from the server.";
                                successMessage.value = "";
                            }
                            if (responseBody.error) {
                                errorDetail.value = responseBody.error;
                            }
                            else {
                                errorDetail.value = "";
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
        var __returned__ = { props: props, isFormVisible: isFormVisible, emailInput: emailInput, passwordInput: passwordInput, confirmPasswordInput: confirmPasswordInput, successMessage: successMessage, errorMessage: errorMessage, errorDetail: errorDetail, resetPasswordClick: resetPasswordClick, ref: vue_2.ref };
        Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true });
        return __returned__;
    }
});
