"use strict";
exports.__esModule = true;
var vue_1 = require("vue");
var vue_2 = require("vue");
exports["default"] = (0, vue_1.defineComponent)({
    __name: 'ManageProfileButtons',
    props: {
        user: null
    },
    setup: function (__props, _a) {
        var expose = _a.expose;
        expose();
        var props = __props;
        var __returned__ = { props: props, onBeforeMount: vue_2.onBeforeMount, ref: vue_2.ref };
        Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true });
        return __returned__;
    }
});
